<template>
  <div id="app">
    <main-component></main-component>
  </div>
</template>

<script>
import mainComponent from './components/main-component.vue'

export default {
  name: 'App',
  components: {
    mainComponent
  }
}
</script>
