const CACHE_DURATION_MS = 120 * 1000;
const LOCK_RETRY_INTERVAL_MS = 10;
const RETRY_DELAY_MS = 500;

let lock = false;

export async function getApi(tenant_name){

    if (process.env && process.env.VUE_APP_BASE_URL){
        return {api_url: process.env.VUE_APP_BASE_URL, tenant_name};
    }

    const tenantFromLS = localStorage.getItem(`${tenant_name}_tenant`);
    let localCache = null;
    if(tenantFromLS){
        try {
            localCache = JSON.parse(tenantFromLS);
        }catch (e){
            console.error(e);
            return null;
        }
    }

    const cacheTime = localStorage.getItem(`${tenant_name}_tenant_time`);

    const now = new Date().getTime();
    if (localCache && cacheTime && (now - Number(cacheTime)) < CACHE_DURATION_MS) {
        return localCache;
    }

    if (lock) {
        await new Promise(resolve => setTimeout(resolve, LOCK_RETRY_INTERVAL_MS));
        return await getApi(tenant_name);
    }

    lock = true;
    try {
        const response = await fetch(`https://configs.atmos.co.il/${tenant_name}.json`);
        const status = response.status;
        if(status !== 200){
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY_MS));
            return await getApi(tenant_name);
        }
        const json = await response.json();

        if (json && typeof json.api_url === 'string') {
            if(json.api_url){
                if(json.api_url.slice(-1) !== '/'){
                    json.api_url = `${json.api_url}/`
                }
            }
            localStorage.setItem(`${tenant_name}_tenant`, JSON.stringify(json));
            localStorage.setItem(`${tenant_name}_tenant_time`, new Date().getTime().toString());
            localCache = json;
        } else {
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY_MS));
            return await getApi(tenant_name);
        }
    } catch (error) {
        console.log(error);
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY_MS));
        return await getApi(tenant_name);
    } finally {
        lock = false;
    }

    return localCache;
}
