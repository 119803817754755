<template>
  <div class="bg-image" :style="{'background-image':bgImage}">
    <configs-error v-if="invalidTenant"></configs-error>
    <div v-else-if="order" class="backdrop">
      <div class="container">
        <link rel="stylesheet" type="text/css" :href="styleCss">
        <div class="header">
          <div class="logo"
               :style="{'background-color': document.body.offsetWidth < 550 ? style.colors.mobile_header : 'rgba(0, 0, 0, 0)'}">

            <a :href="businessSiteUrl">
              <img :src="'https://files.geteat.co.il/atmos/'+order.restaurant_id+'/images/'+style.images.logo_horizontal"/>
            </a>
            <div class="logo__text">
              <div>

                <div class="logo__restaurunt-name"
                     :style="{color: document.body.offsetWidth > 550 ? style.colors.primary : ''}">
                  {{ order.restaurant.name }}
                </div>
                <div class="logo__branch-name">{{ order.branch_name }}</div>
                <div class="logo__tel">{{ order.branch.phone }}</div>
              </div>
              <a :href="'tel:'+order.branch.phone" class="cell">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M18.9147 15.4185L16.0319 12.5354C15.4577 11.9636 14.5068 11.9809 13.9126 12.5753L12.4603 14.0274C12.3685 13.9768 12.2736 13.924 12.1737 13.8679C11.2565 13.3598 10.0013 12.6632 8.68037 11.3414C7.35554 10.0167 6.65838 8.7595 6.14864 7.84176C6.09485 7.74453 6.04332 7.65079 5.99242 7.56177L6.96717 6.58848L7.44639 6.10867C8.0415 5.5134 8.05791 4.56279 7.48505 3.98924L4.60228 1.10582C4.02943 0.533073 3.07812 0.550449 2.48301 1.14572L1.67055 1.96285L1.69275 1.98489C1.42032 2.33251 1.19267 2.73343 1.02326 3.16578C0.867091 3.57732 0.769864 3.97004 0.725406 4.36356C0.344755 7.51924 1.78681 10.4033 5.70037 14.3169C11.1101 19.7263 15.4696 19.3176 15.6577 19.2977C16.0673 19.2487 16.4599 19.1508 16.8588 18.9959C17.2874 18.8285 17.688 18.6011 18.0354 18.3293L18.0532 18.3451L18.8763 17.5391C19.4702 16.9439 19.4873 15.993 18.9147 15.4185Z"
                      fill="white"/>
                </svg>
              </a>
            </div>
          </div>
          <div class="titles-headers">
            <div class="titles-headers__order-continer"
                 :style="{'background-color': document.body.offsetWidth < 550 ? style.colors.mobile_header : 'rgba(0, 0, 0, 0)'}"
            >
              <div class="titles-headers__order">
                <div class="titles-headers__order_title">{{ $t('Order') }} #{{ order.unique_id }}</div>
                <div class="titles-headers__order_date">{{ dateLocale }}</div>
                <div v-if="futureDate" class="titles-headers__order_date">{{ $t('Future order') }} {{ futureDate }}</div>
              </div>
            </div>
            <div class="titles-headers__time"
                 :style="{'background-color': document.body.offsetWidth < 550 ? style.colors.mobile_header : 'rgba(255,255,255)'}">
              <!--              <div class="titles-headers__time_clock" v-if="digits && order.arrival_time===null">-->
              <!--                <div class="titles-headers__time_digit" >{{ digits[0] }}-->
              <!--                </div>-->
              <!--                <div class="titles-headers__time_digit" >{{ digits[1] }}-->
              <!--                </div>-->
              <!--                :-->
              <!--                <div class="titles-headers__time_digit" >{{ digits[2] }}-->
              <!--                </div>-->
              <!--                <div class="titles-headers__time_digit" >{{ digits[3] }}-->
              <!--                </div>-->
              <!--                :-->
              <!--                <div class="titles-headers__time_digit" >{{ digits[4] }}-->
              <!--                </div>-->
              <!--                <div class="titles-headers__time_digit" >{{ digits[5] }}-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="titles-headers__time_clock" v-if=" order.arrival_time===null">
                {{ $t('Before') + ' ' + days + ' ' + $t('days') }}
              </div>
              <div class="titles-headers__time_title">{{ $t(statuses[order.status]) }}</div>

              <div class="titles-headers__time_arrival">
                <div style="display: flex; width: 100%;"  v-if="order.arrival_time && !order.future_date">
                  <span class="titles-headers__time_title" v-text=" arrivalTime "/>
                </div>

              </div>
            </div>
            <div v-if="order.cart.type" class="titles-headers__address">
              <div class="titles-headers__address_text">
                <div class="titles-headers__address_title" :style="{ color:style.colors.text}">
                  {{ $t('Branch address for takeaway') }}
                </div>
                <div class="titles-headers__address_address">
                  {{ `${this.order.branch.address} ${this.order.branch.city}` }}
                </div>
              </div>
              <img
                v-if="style.images.takeaway_image"
                :src="`https://files.geteat.co.il/atmos/${order.restaurant_id}/images/${style.images.takeaway_image}`"
              />
              <img v-else src="@/assets/img/takeaway.svg"/>
            </div>
            <div v-else-if="address" class="titles-headers__address">
              <div class="titles-headers__address_text">
                <div class="titles-headers__address_title" :style="{ color:style.colors.text}">
                  {{ $t('Order will be deliver to') }}
                </div>
                <div class="titles-headers__address_address" v-text="address"/>
              </div>
              <img v-if="style.images.takeaway_image"
                   :src="`https://files.geteat.co.il/atmos/${order.restaurant_id}/images/${style.images.delivery_image}`"/>
              <img v-else src="@/assets/img/delivery.svg"/>
            </div>
            <div class="titles-headers__address user" v-if="order.user">
              <img v-if="order.user.avatar" :src="'https://files.geteat.co.il/images/avatars/'+order.user.avatar"/>
              <img v-else src="@/assets/img/profile.svg"/>
              <div class="titles-headers__address_text">
                <div class="titles-headers__address_title user" :style="{ color:style.colors.text}"
                     v-text="order.user.phone"/>
                <div class="titles-headers__address_address user" v-text="order.user.name"/>
              </div>
            </div>
            <div class="titles-headers__address user" v-if="!order.user && order.client_name !== ''">
              <img src="@/assets/img/profile.svg"/>
              <div class="titles-headers__address_text">
                <div class="titles-headers__address_address user" v-text="order.client_name"/>
              </div>
            </div>
          </div>
        </div>

        <div class="main">
          <div class="order-items">
            <div class="order-items__items-container">
              <div v-for="item in order.cart.items" :key="item.id" class="order-items__item_container">
                <div class="order-items__item">
                  <img v-if="item.image && !item.image.endsWith('/menu/sm-')" :src="item.image"/>
                  <div class="order-items__text">
                    <div class="order-items__text_title" :style="{ color:style.colors.text}">{{ item.name }}</div>
                    <div class="order-items__text_addon" v-if="item.item_type===1">
                      {{ $t('Weight: ') + item.total_amount + ' ' + $t('KG') }}
                    </div>
                    <div class="order-items__text_addon" v-if="item.amount>1">{{ $t('Amount') + ': ' + item.amount }}
                    </div>

                    <div v-for="(extra,i) in item.extras" :key="extra.name+i" class="order-items__text_addon"
                         :style="{ color:style.colors.text}">{{ extra.extra_name }} {{ extra.amount }}
                    </div>
                    <div v-for="(discount) in item.active_discounts" :key="discount.id" class="order-items__text_addon"
                         :style="{ color:style.colors.text}">{{ discount.name }} {{ formatCurrency(discount.discount) }}
                    </div>

                    <div class="order-items__note" :style="{ color:style.colors.danger}" v-if="item.notes">
                      {{ $t('Notes:') }} {{ item.notes }}
                    </div>
                    <div class="order-items__note" :style="{ color:style.colors.danger}" v-if="item.diner_name">
                      {{ $t('Diner name:') }} {{ item.diner_name }}
                    </div>
                  </div>
                  <div class="order-items__price">
                    <div class="order-items__price_title" :style="{ color:style.colors.text}">
                      {{ formatCurrency(item.price) }}
                    </div>
                    <div v-for="(extra,i) in item.extras" :key="extra.name+i" class="order-items__price_addon"
                         :style="{ color:style.colors.text}">{{ formatCurrency(extra.price) }}
                    </div>
                  </div>
                </div>
                <div v-for="child in item.children" :key="child.id" class="order-items__item_container child">
                  <div class="order-items__item">
                    <img v-if="child.image && !child.image.endsWith('/menu/sm-')" :src="child.image"/>
                    <div class="order-items__text">
                      <div class="order-items__text_title" :style="{ color:style.colors.text}">{{ child.name }}</div>

                      <div v-for="extra in child.extras" :key="extra.name" class="order-items__text_addon"
                           style="direction: rtl;align-self: start" :style="{ color:style.colors.text}">
                        {{ extraString(extra) }}
                      </div>
                    </div>
                    <div class="order-items__price">
                      <div class="order-items__price_title" :style="{ color:style.colors.text}">
                        {{ formatCurrency(child.price) }}
                      </div>
                      <div v-for="extra in child.extras" :key="extra.name" class="order-items__price_addon"
                           style="direction: ltr" :style="{ color:style.colors.text}">
                        {{ extra.price ? formatCurrency(extra.price) : '' }}
                      </div>
                    </div>
                  </div>
                  <div class="order-items__note" v-text="child.notes"/>
                </div>
              </div>
              <div class="order-items__item-container">
                <div class="order-items__item" v-for="(discount) in order.cart.active_discounts" :key="discount.id">
                  <div class="order-items__text">
                    <div class="order-items__text_title" :style="{ color:style.colors.text}">{{ discount.name }}</div>
                    <div class="order-items__note" style="padding: 0 12px 0 0" :style="{color:style.colors.danger}" v-if="discount.description">
                      {{ $t('Coupon:') }} {{ discount.description }}
                    </div>
                    <div class="order-items__note" style="padding: 0 12px 0 0" :style="{color:style.colors.danger}" v-if="discount.type === 9">
                      {{ $t('Points') }}
                    </div>
                  </div>
                  <div class="order-items__price">
                    <div class="order-items__price_title" :style="{ color:style.colors.text}">
                      {{ formatCurrency(discount.discount) }}
                    </div>
                  </div>
                </div>

              </div>
              <div class="order-items__item_container">
                <div v-if="order.cart.delivery_price" class="order-items__item">
                  <div class="order-items__text">
                    <div class="order-items__text_title" :style="{ color:style.colors.text}">{{ $t('Delivery price') }}
                    </div>
                  </div>
                  <div class="order-items__price">
                    <div class="order-items__price_title" :style="{ color:style.colors.text}">
                      {{ formatCurrency(order.cart.delivery_price) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="order-items__item_container" v-if="tipAmount">
                <div class="order-items__item">
                  <div class="order-items__text">
                    <div class="order-items__text_title" :style="{ color:style.colors.text}">{{ $t('Tip') }}</div>
                  </div>
                  <div class="order-items__price">
                    <div class="order-items__price_title" :style="{ color:style.colors.text}">
                      {{ formatCurrency(tipAmount) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="order-items__item_container" v-if="refundsAmount">
                <div class="order-items__item">
                  <div class="order-items__text">
                    <div class="order-items__text_title" :style="{ color:style.colors.text}">{{ $t('Total refunds') }}</div>
                  </div>
                  <div class="order-items__price">
                    <div class="order-items__price_title" :style="{ color:style.colors.text}">
                      {{ formatCurrency(refundsAmount) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="order-items__total">
              <div class="order-items__total_text" :style="{color:style.colors.danger}">
                {{ $t('Total') }}
              </div>
              <div class="order-items__total_price" :style="{color:style.colors.danger}">
                {{ formatCurrency(order.sum) }}
              </div>

            </div>
          </div>
          <div class="order-details" :style="{'background-color':style.colors.background, color:style.colors.text}">
            <div class="order-details__comments">
              <div class="order-details__title" :style="{color:style.colors.danger}">{{ $t('Comments') }}</div>
              <div class="order-details__comments_text"
              >
                {{ order.notes ? order.notes : this.lang === 'he' ? 'אין הערות' : 'no comments' }}
              </div>
            </div>
            <div class="order-details__email" v-if="order.user && order.user.email">
              <div class="order-details__email_text">
                <div class="order-details__title" :style="{color:style.colors.titles}">{{ $t('Email for invoice') }}
                </div>
                <!-- <div class="order-details__sub-title">Let’s save some trees</div> -->
              </div>
              <div class="order-details__email_email"
              >
                {{ order.user.email }}
              </div>
            </div>
            <div class="order-details__number">
              <div class="order-details__title" :style="{color:style.colors.titles}">
                {{ $t('Should send cutlery? ') }}{{ order.cutlery ? $t('yes') : $t('no') }}
              </div>
            </div>
            <div class="order-details__number">
              <div class="order-details__title" :style="{color:style.colors.titles}">{{ $t('Number of diners') }}</div>
              <div class="order-details__number_counter">
                <div class="order-details__number_count">{{ order.number_of_diners }}</div>
              </div>
            </div>
            <div v-if="order.points_collected>0" class="order-details__coins">
              <img src="@/assets/img/vip coins.png"/>
              <div class="order-details__text" :style="{color:style.colors.primary}">
                {{ $t('You’ve earned') + order.points_collected + $t('coins for next order!') }}
              </div>
            </div>
          </div>
        </div>
        <div class="other-data">
          <iframe :width="mapWidth" :height="mapHeigh" class="map" v-if="order.trackUrl" :src="order.trackUrl"></iframe>
          <here-map v-else class="map" :coordinates="coordinates"/>
          <!-- <div></div> -->
          <div class="payments" >
            <div class="payments__title" :style="{color:style.colors.titles}">{{ $t('Payments') }}</div>

            <div
              class="payments__itme"
              v-for="payment in order.payments"
              :key="payment.id"
              :style="{color:style.colors.text,  'justify-content':'space-between'}"
            >
              <img v-if="payment.src" :src="`@/assets/${payment.src}`" alt="">
              <div class="payments__text" :style="{  'margin-top':'7px',  'display': 'flex', 'justify-content': 'space-between','width': '100%'}">

                <div class="payment_digits" :style="{'display':'flex','margin-top':'7px'}">
                  <div class="payments__name" :style="{ color:style.colors.text}">{{ $t(payment.title) }} </div>
                  <div  class="payments__name" :style="{ color:style.colors.text}" v-text="(payment.last_digits )">  </div>
                </div>
                <div style="direction: ltr" class="payments__count" >
                  {{ formatCurrency(payment.amount) }}
                </div>

              </div>
            </div>
          </div>
        </div>
        <a v-if="false" target="_blank" :href="`https://wa.me/+972${order.branch.phone.substr(1)}`" class="chat">
          <img src="@/assets/img/chat.png" alt=""/>
        </a>
        <button v-if="false" class="report">
          <span class="img-border">
            <img src="@/assets/img/search-problem 1.png" alt=""/>
          </span>
        </button>
        <a  href="https://www.atmos.co.il" target="_blank">
          <div dir="ltr" class="cat-history_copyright geteat-copy">
            Powered by
            <img height="14" src="@/assets/img/logo.svg" alt="Atmos">
          </div>
        </a>
      </div>
    </div>
    <div v-else class="loading-container">
      <lottie-vue-player
        src="loading-animation.json"
        :player-controls="false"
        style="width: 100%; height:400px"
        loop
        autoplay
      ></lottie-vue-player>
    </div>
  </div>
</template>

<script>
import hereMap from './here-map';
import moment, {now} from 'moment'
import ConfigsError from "@/components/configs-error.vue";

const languages = [
  'he',
  'en',
  'fr'
]
export default {
  name: 'main-component',
  components: {
    ConfigsError,
    hereMap
  },
  data() {
    return {
      url:null,
      order: null,
      style: {
        restaurant_id: 0,
        restaurant_name: "",
        images: {
          loader: "",
          logo: "",
          icon: null,
          start_background_desktop: "",
          start_background_mobile: "",
          start_background_kiosk: null,
          menu_background_desktop: "",
          menu_background_mobile: "",
          menu_background_kiosk: null,
          delivery_image: "",
          takeaway_image: "",
          sitting_image: null,
          credit_card_image: null,
          cash_image: null,
          members_club_image: null,
          logo_horizontal: ""
        },
        fonts: {
          normal_eot: null,
          normal_woff: null,
          normal_ttf: null,
          bold_eot: null,
          bold_woff: null,
          bold_ttf: null
        },
        radiuses: {
          cards: "12px",
          buttons: "30px",
          inputs: "30px"
        },
        colors: {
          background: "",
          primary: "",
          secondary: "",
          titles: "",
          text: "",
          buttons: "",
          buttons_text: "",
          buttons_close: "",
          input_background: "",
          danger: "",
          success: "",
          mobile_header: "",
          mobile_burger: ""
        },
        brand_css: "",
        custom_css_desktop: "",
        custom_css_mobile: "",
        custom_css_kiosk: ""
      },
      paymentsType: [
        {type:0, title: 'Cash', src: 'payments/0.svg'},
        {type:1, title: 'Card ends with', src: 'payments/1.svg'},
        {type:2,title: 'Club member points', src: 'payments/2.svg'},
        {type:3,title: 'Credit emv', src: 'payments/3.svg'},
        {type:4,title: 'Compensation', src: 'payments/4.svg'},
        {type:5,title: 'Manager discount', src: 'payments/5.svg'},
        {type:6,title: 'MultiPass', src: 'payments/6.png'},
        {type:7,title: 'Business client', src: 'payments/7.svg'},
        {type:8,title: 'Cibus', src: 'payments/8.png'},
        {type:9,title: 'Tenbis', src: 'payments/9.png'},
        {type:10,title: 'Bit', src: 'payments/10.png'},
        {type:11,title: 'Goodi', src: 'payments/11.png'},
        {type:12,title: 'Credit Input', src: 'payments/12.svg'},
        {type:13,title: 'Haat Delivery', src: 'payments/13.svg'},
        {type:14,title: 'Wolt', src: 'payments/14.png'},
        {type:15,title: 'Mishloha', src: 'payments/15.png'},
        {type:16,title: 'Cheque', src: 'payments/16.svg'},
        {type:17,title: 'Bank Transfer', src: 'payments/17.svg'},
        {type:18,title: 'Gift Card', src: 'payments/18.svg'},
        {type:19,title: 'KashKash', src: 'payments/19.png'},
        {type:20,title: 'Business Logic', src: 'payments/a.png'},

      ],
      css: 'div{ background-color: #f00;}',
      customCssDesktop: '',
      customCssKiosk: '',
      customCssMobile: '',
      styleCss: '',
      digits: '000000',
      days: 0,
      statuses: [
        'Opened',
        'In preparation',
        'Ready',
        'In delivery',
        'Closed',
        'Canceled',
        'Unresolved payment',
      ],
      map: null,
      bgImage: '',
      document: document,
      coordinates: [],
      currency_info: null,
    }
  },
  async mounted() {
    if(this.$root.baseUrl && !this.baseUrl){
      this.baseUrl = this.$root.baseUrl;
    }
  const search = JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    this.$i18n.locale = search.lang || 'he'
    const html = document.querySelector('html')
    if (this.lang === 'he') {
      html.dir = 'rtl'
      html.lang = 'he'
    }else{
      html.dir = 'ltr'
    }
    const queryString = search.order_id
    //  const queryString  = '4eac8a088c10cc5af3470239b083dea5'
    let form = new FormData();
    form.append('order_id', queryString)
    const mobile = document.body.offsetWidth < 767
    try {
      let res = await fetch(`${this.baseUrl}orders/getClosedOrder`, {
        method: 'POST',
        body: form
      })
      let data = await res.json()
      await new Promise(resolve => setTimeout(resolve, 2000));
      this.order = data.result;
      if(this.order && this.order.restaurant_id){
        const businessRequestBody = new FormData();
        businessRequestBody.append('restaurant_id', this.order.restaurant_id)
        let businessResponse = await fetch(`${this.baseUrl}restaurants/getBusiness`, {
          method: 'POST',
          body: businessRequestBody
        })
        businessResponse = await businessResponse.json()
        if(businessResponse.success && businessResponse.result){
          const businessDetails = businessResponse.result
          if(businessDetails.rest_url){
            if(businessDetails.rest_url.indexOf('http') === -1) {
              this.url = `https://${businessDetails.rest_url}`
            }else{
              this.url = businessDetails.rest_url
            }
          }
          if(businessDetails.currency_info){
            this.currency_info = businessDetails.currency_info
          }
          if(businessDetails.primary_language !== undefined && languages[businessDetails.primary_language]){
            const lang = languages[businessDetails.primary_language]
            this.$root.$i18n.locale = lang
            document.documentElement.setAttribute('lang', lang)
            if(lang === 'he'){
              document.documentElement.setAttribute('dir', 'rtl')
            }else{
              document.documentElement.setAttribute('dir', 'ltr')
            }
          }

          document.querySelector('title').innerText = `${this.$t('Order summary')}`
        }
      }
      if(this.invalidTenant){
        console.warn('tenant don\'t match restaurant id');
        return;
      }
      this.order.payments.forEach((payment) => {
        if (this.paymentsType[payment.type]) {
          payment.title = this.paymentsType[payment.type].title
          payment.src = this.paymentsType[payment.type].src
        } else {
          payment.title = this.paymentsType[0].title
          payment.src = this.paymentsType[0].src
        }
      });

      const coordinates = []

      if (this.order.branch.coordinates) coordinates.push(typeof this.order.branch.coordinates === 'string' ? JSON.parse(this.order.branch.coordinates) : this.order.branch.coordinates)
      if (this.order.cart.address && this.order.cart.address.coordinate) coordinates.push(typeof this.order.cart.address.coordinate === 'string' ? JSON.parse(this.order.cart.address.coordinate) : this.order.cart.address.coordinate)
      this.coordinates = coordinates
      form = new FormData();
      form.append('restaurant_id', this.order.restaurant_id)
      res = await fetch(`${this.baseUrl}restaurants/getBrand`, {
        method: 'POST',
        body: form
      })
      data = await res.json()
      this.style = data.result

      if (mobile) {
        const elCustomCssMobile = document.createElement('style')
        elCustomCssMobile.innerText = this.style.custom_css_mobile
        document.head.appendChild(elCustomCssMobile)
      } else {
        const elCustomCssDesktop = document.createElement('style')
        elCustomCssDesktop.innerText = this.style.custom_css_desktop
        document.head.appendChild(elCustomCssDesktop)
      }
      this.styleCss = `https://files.geteat.co.il/atmos/${this.order.restaurant_id}/${this.style.brand_css}`
      this.bgImage = mobile ? `url("https://files.geteat.co.il/atmos/${this.order.restaurant_id}/images/xxl-${this.style.images.start_background_mobile}")` : `url("https://files.geteat.co.il/atmos/${this.order.restaurant_id}/images/xxl-${this.style.images.start_background_desktop}")`

    } catch(e) {
      console.error('error',e);
      window.location.href = "http://www.atmos.co.il";
    }
    this.setTime()
    setInterval(this.setTime, 1000)

    this.order.cart.items = this.order.cart.items.map(item => {
      item.extras = item.extras_categories.reduce((acc, cat) => {
        acc.push(...cat.extras)
        return acc
      }, [])
      item.children.forEach(child => {
        child.extras = child.extras_categories.reduce((acc, cat) => {
          acc.push(...cat.extras)
          return acc
        }, [])
      })
      return item;
    });


    this.order.cart.items = this.order.cart.items.map(item => {
      const extras = [];
      item.extras.forEach(extra => {

        if (extras.map(e => e.id).includes(extra.id)) {
          extras.find(e => e.id === extra.id).count++
        } else {
          extra.count = 1
          extras.push(extra)
        }

      })
      item.extras = extras


      item.extras.forEach(e => {
        e.name += e.count
        e.name_en += +e.count
        e.price *= e.count
      })

      return item;
    })


  },
  computed: {
    invalidTenant(){
      return this.order && this.order.id && this.$root.restaurantId && this.order.restaurant_id !== this.$root.restaurantId;
    },
    lang(){
      return this.$i18n.locale
    },
    address() {
      const he = this.lang === 'he'
      if (!this.order.cart) return ''
      const ad = this.order.cart.address
      if (!ad) return ''
      return `${he ? ad.street_name : ad.street_name_en} ${ad.number} ${he ? ad.city_name : ad.city_name_en}`
    },
    arrivalTime() {
      const date = moment(this.order.arrival_time, '"YYYY-MM-DD HH:mm:ss"')
      let result = ""
      if(this.lang==='he')
      {
        result ="זמן הגעה משוער: "
      }
      if(this.lang==='en')
      {
        result ="Arrival time: "
      }
      if (date.isValid()) {
        let res = ''
        let diffDays = date.diff(moment().endOf('day'), 'days')
        if (date > moment().endOf('day')) diffDays++
        switch (diffDays) {
          case -1:
            res = this.$t('Yesterday');
            break;
          case 0:
            res = this.$t('Today');
            break;
          case 1:
            res = this.$t('Tomorrow');
            break;
          default:
            /*if (diffDays > 1 && diffDays < 5) res = he ? `בעוד ${diffDays} ימים` : `In ${diffDays} days`
            else if (diffDays < -1 && diffDays > -5) res = he ? `לפני ${-diffDays} ימים` : `${-diffDays} days ago`
            else res = date.format('DD/MM/YY')*/
            if (diffDays > 1 && diffDays < 5) res = this.$t('In {days} days', {days: diffDays})
            else if (diffDays < -1 && diffDays > -5) res = this.$t('{days} days ago', {days: -diffDays})
            else res = date.format('DD/MM/YY')
        }
        res += ` ${this.$t('at')} `
        res += date.format('HH:mm')
        return result + res
      }

      return this.lang === 'he' ? date.lang('he').format('LLLL') : date.format('LLLL')
    },

    dateLocale() {
      const date = moment(this.order.date, '"YYYY-MM-DD HH:mm:ss"')
      if (date.isValid()) {
        let res = ''
        let diffDays = date.diff(moment().endOf('day'), 'days')
        if (date > moment().endOf('day')) diffDays++
        switch (diffDays) {
          case -1:
            res = this.$t('Yesterday');
            break;
          case 0:
            res = this.$t('Today');
            break;
          case 1:
            res = this.$t('Tomorrow');
            break;
          default:
            if (diffDays > 1 && diffDays < 5) res = this.$t('In {days} days', {days: diffDays})
            else if (diffDays < -1 && diffDays > -5) res = this.$t('{days} days ago', {days: -diffDays})
            else res = date.format('DD/MM/YY')
        }
        res += ` ${this.$t('at')} `
        res += date.format('HH:mm')
        return res
      }

      return this.lang === 'he' ? date.locale('he').format('LLLL') : date.format('LLLL')
    },
    futureDate() {
      if (!this.order.future_date || this.order.future_date === 'null') return ''
      const date = moment(this.order.future_date, '"YYYY-MM-DD HH:mm:ss"')
      if (!date.isValid()) return ''
      let res = ''
      let diffDays = date.diff(moment().endOf('day'), 'days')
      if (date > moment().endOf('day')) diffDays++
      switch (diffDays) {
        case -1:
          res = this.$t('Yesterday');
          break;
        case 0:
          res = this.$t('Today');
          break;
        case 1:
          res = this.$t('Tomorrow');
          break;
        default:
          if (diffDays > 1 && diffDays < 5) res = this.$t('In {days} days', {days: diffDays})
          else if (diffDays < -1 && diffDays > -5) res = this.$t('{days} days ago', {days: -diffDays})
          else res = date.format('DD/MM/YY')
      }
      res += ` ${this.$t('at')} `
      res += date.format('HH:mm')
      return res
      // return this.lang === 'he' ? date.lang('he').format('LLLL') : date.format('LLLL')
    },
    mapWidth() {
      const clientWidth = document.body.clientWidth
      if (clientWidth > 1110) {
        return `${(clientWidth - 120) / 4 * 3}px`
      }
      if (clientWidth > 870) {
        return `${(clientWidth - 120) / 5 * 3}px`
      }
      if (clientWidth > 790) {
        return `${(clientWidth - 40) / 5 * 3}px`
      }
      return `${clientWidth - 40}px`
    },
    mapHeigh() {
      const clientWidth = document.body.clientWidth
      const clientHeight = document.body.clientHeight
      if (clientWidth > 870) {
        return `${clientHeight / 2 - 90 - 38}px`
      }
      return `${400}px`
    },
    refundsAmount(){
      const refunds = this.order.payments.reduce((acc, pay) => acc + (pay.refunded_amount || 0), 0)
      if(refunds > 0){
        return -(refunds)
      }
      return refunds
    },
    tipAmount() {
      return this.order.payments.reduce((acc, pay) => acc + (pay.tip_amount || 0), 0);
    },
    businessSiteUrl(){
      if(this.url){
        return this.url
      }
      return 'javascript:void(0)';
    },
    currencySign(){
      if(this.currency_info && this.currency_info.symbol){
        return this.currency_info.symbol
      }
      if(this.lang === 'he'){
        return '₪'
      }
      return '$'
    }
  },
  methods: {
    formatCurrency(price) {
      return price < 0 ? `-${this.currencySign}${price.toString().replace('-', '')}` : `${this.currencySign}${price.toString()}`
    },
    extraString(extra) {
      if (extra.amount && extra.name)
        return (extra.amount.toString()[0] === '-' ? (extra.amount.toString().replace('-', '') + '-') : extra.amount) + ' x ' + extra.name
    },
    setTime() {
      if (moment('2015-01-01').startOf('day').seconds(moment().diff(moment(this.order.date, 'YYYY-MM-DD HH:mm:ss'), 'seconds', true)).format('MMDD') === '0101')
        this.digits = moment('2015-01-01').startOf('day').seconds(moment().diff(moment(this.order.date, 'YYYY-MM-DD HH:mm:ss'), 'seconds', true)).format('HHmmss')
      else {
        this.digits = ''
        this.days = Math.round(moment().diff(moment(this.order.date), now(), true) / 1000 / 60 / 60 / 24)
      }
    }
  }
}
</script>
