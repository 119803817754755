<template>
<div class="wrap-map">
    <!--In the following div the HERE Map will render-->
    <div id="mapContainer" class="map" ref="hereMap"></div>
</div>
</template>

<script>
function switchMapLanguage(map, platform) {
    // Create default layers
  //
    let defaultLayers = platform.createDefaultLayers({
        lg: 'he'
    });
    // Set the normal map variant of the vector map type
    map.setBaseLayer(defaultLayers.vector.normal.map);

    // Display default UI components on the map and change default
    // language to simplified Chinese.
    // Besides supported language codes you can also specify your custom translation
    // using H.ui.i18n.Localization.
    // var ui = window.H.ui.UI.createDefault(map, defaultLayers, 'en-US');

    // Remove not needed settings control
    // ui.removeControl('mapsettings');
}
export default {
    name: "HereMap",
    props: {
        coordinates: Array
        // center object { lat: 40.730610, lng: -73.935242 }
    },
    data() {
        return {
            platform: null,
            apikey: "uoL9zgJuNbLgeW_0fKb-PAjv_tXGef5gvQi5SQifJ04",
            // You can get the API KEY from developer.here.com,
            center: {
                lat: 0,
                lng: 0
            }
        };
    },
    async mounted() {
        // Initialize the platform object:
        const platform = new window.H.service.Platform({
            apikey: this.apikey
        });
        this.platform = platform;
        this.initializeHereMap()
    },
    methods: {
        initializeHereMap() { // rendering map
            if (!this.coordinates.length) return
            const mapContainer = this.$refs.hereMap;
            const H = window.H;
            // Obtain the default map types from the platform object
            const maptypes = this.platform.createDefaultLayers();
            const center = this.coordinates.reduce((acc, loc) => {
                acc.lat += loc.lat
                acc.lng += loc.lng
                return acc
            }, {
                lat: 0,
                lng: 0
            })
            center.lat /= this.coordinates.length
            center.lng /= this.coordinates.length

            const map = new H.Map(mapContainer, maptypes.vector.normal.map, {
                zoom: 15,
                center
            });
            addEventListener('resize', () => map.getViewPort().resize());
            // new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

            switchMapLanguage(map, this.platform);
            const svg = `<svg width="40" height="57" viewBox="0 0 40 57" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.8416 5.97281C26.1976 -1.67119 13.8033 -1.67119 6.15825 5.97281C-0.72975 12.8608 -1.50514 25.8302 4.3404 33.6239L19.9999 56.2393L35.6595 33.6239C41.505 25.8302 40.7296 12.8608 33.8416 5.97281ZM20.1906 26.0854C16.6216 26.0854 13.729 23.1928 13.729 19.6239C13.729 16.055 16.6216 13.1623 20.1906 13.1623C23.7595 13.1623 26.6521 16.055 26.6521 19.6239C26.6521 23.1928 23.7595 26.0854 20.1906 26.0854Z" fill="{{FILL}}"/></svg>`
            const markers = this.coordinates.map((cord, i) => new H.map.Marker(cord, {
                icon: new H.map.Icon(svg.replace('{{FILL}}', ['#EA0000', '#1FBCEE'][i]))
            }))
            const group = new H.map.Group()
            group.addObjects(markers)
            map.addObject(group);
            if(markers.length > 1){
                map.getViewModel().setLookAtData({
                    bounds: group.getBoundingBox()
                })
                setImmediate(() => {
                    if (map.getZoom()) map.setZoom(map.getZoom() - 0.4)
                    else setTimeout(() => {
                        if (map.getZoom()) map.setZoom(map.getZoom() - 0.4)
                        else setTimeout(() => {
                            if (map.getZoom()) map.setZoom(map.getZoom() - 0.4)
                            else setTimeout(() => {
                                if (map.getZoom()) map.setZoom(map.getZoom() - 0.4)
                            }, 3000)
                        }, 1000)
                    }, 100)
                })
            }
        }
    },
    watch: {
        coordinates() {
            this.initializeHereMap();
        }
    }
};
</script>

<style scoped>
#map {
    width: 60vw;
    min-width: 360px;
    text-align: center;
    margin: 5% auto;
    background-color: #ccc;
}

.wrap-map {
    overflow: hidden;
    border-radius: 12px;
}

.map {
    width: 100%;
    height: 100%;
}
</style>
