import Vue from 'vue'
import App from './App.vue'
import "./assets/style/main.scss"
import i18n from './i18n'
import {getApi} from "@/getApi";
import ConfigsError from "@/components/configs-error.vue";
import "./lottie-vue-player.client";


Vue.config.productionTip = false

const path = window.location.pathname;
const urlRestId = path.split("/").filter(p => p)[0]
getApi(urlRestId).then((tenantResponse) => {
  if(tenantResponse && tenantResponse.api_url){
    new Vue({
      render: h => h(App),
      i18n,
      data(){
        return {
          baseUrl: tenantResponse.api_url,
          restaurantId: tenantResponse.restaurant_id
        }
      }
    }).$mount('#app')
  }else{
    new Vue({
      render: h => h(ConfigsError),
      i18n,
    }).$mount('#app')
  }
})

